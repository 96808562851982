

.App-secondary-header-button{
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 2px solid white;
  border-radius: 10px;
  font-size: 22px;
  font-weight: bold;
  color: white;
  height: 55px;
  width: 160px;
  cursor: pointer;
  background-color: #248C2D;
}

.App-secondary-header-button:hover{
  width: 98%;
}
.App-secondary-header {
  display:flex;
  width: 100%;
  height: 100%;
  margin: 10px 0 40px;
  border-left: 3px solid #248C2D;

}

.App-secondary-header-title{
  display: inline-block;
  flex-basis: fit-content;
  color: black;
  font-weight: normal;
  padding-left: 15px;
  margin: 0;
}

.App-secondary-header-sub-text{
  font-size: 12px;
}

.App-secondary-header-title-span{
  background-color: #A41473;
  color: white;
  font-weight: bold;
}

@media screen and (max-width: 992px) {
  .App-header {
    margin: 0rem 0rem 0rem 0rem;
    font-weight: bolder;
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }
}
