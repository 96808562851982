
.box-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 30px 0;


}

.box-border{
  flex: 0 0 30%;
  text-align: center;
  margin: 5px;
  padding: 8px 4px;
}

.box-title{
  margin: 0;
  color: #A41473;
}

.box-title-new{
  background-color: #248C2D;
  color: white;
  padding: 2px 10px !important;
}

.box-descreption{
  margin: 0;
  text-align: center;
}


.sides {
  border: 2px solid black;
	border-image: linear-gradient(to left, #A41473, #248C2D) 1 1;
}
.sides-2 {
  border: 2px solid black;
  border-image: linear-gradient(to right, #A41473, #248C2D) 1 1;
}
