
.topbar-wrapper{
  height: 60px;
  background-color: #A41473;
  color: white;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0px 10px;
  font-weight: bolder;
  font-size: 16px;
  align-items: center;
  flex-wrap: wrap;
}

.topbar-wrapper-text{
  margin: 0 !important;
}

@media screen and (max-width: 992px) {
  .topbar-wrapper{
    height: 75px;
    font-size: 14px;
  }
}
