.App {
  text-align: center;
}


.App-container{
}

.app-premint-link{
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-weight:600;
}

.dao-list{
  margin: 20px 20px 50px;
}

.dao-list-item{
  margin: 10px 0;
}


.App-footer{
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 1rem;
  font-weight: bolder;
  flex-direction: row;
}

.premint-end{
  margin-bottom: 40px;
}
