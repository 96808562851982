.timline-time{
  margin: 0;

}


.timeline-wrapper{
  align-content: flex-start;
  margin: 0px;
  width: 100%;
}

.timline-right{
  flex: 2;
}

.dot-color{
  background-color: #248C2D !important;
}

.timline-utc{
  font-weight: normal !important;
}
