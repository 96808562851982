@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.image-container {
  padding: 2.5rem 0;
}

.App-image-wrapper{
  height: 500px;
}

.App-image-card{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  margin: 0.4rem;
}

.App-image{
  width: 100%;
  color: #248C2D;
  text-align: center;
}

.App-image-text{
  color: #248C2D;
  text-align: center;
  padding: 0.2rem;
  margin: 0.5rem 0 1rem;
}

.slide {
  transform: scale(0.7);
  transition: transform 300ms;
  opacity: 0.5;
}

.slideWrapper {
  display: flex;
  justify-content: center;
}

.prevArrow {
  position: absolute;
  top: 40%;
  left: 0px;
  z-index: 100;
  cursor: pointer;
  font-size: 2rem;
  color: #A41473;
}

.nextArrow {
  position: absolute;
  top: 40%;
  right: 0px;
  z-index: 100;
  cursor: pointer;
  font-size: 2rem;
  color: #A41473;
}

.slideText{
  font-size: 12px;
  margin: 0.2rem 0.5rem;
}
