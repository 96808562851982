
.diagram-wrapper{
  margin: 50px auto;
  display: flex;
  justify-content: center;
}

.diagram-img{
  width: 100%;

}
