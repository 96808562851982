body {
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F5F5;
}

a  {
  text-decoration: none;
  color: #A41473;
  margin: 0 0.1rem;
}

a:hover {
    color:#A41473;
    text-decoration: underline;
    cursor:pointer;
}

h1  {
  color: #A41473;
}

h2  {
  color: #A41473;
}

h3  {
  color: #A41473;
}

p{
  text-align: justify;
  text-justify: inter-word;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
