
.App-logo {
  flex-basis: auto;
}

.App-logo-background{
  background-image: url("logo.svg");
  height: 50px;
  width: 180px;
  display: inline-block;
  background-repeat: no-repeat;
  margin-top: 15px;
}

.App-link-box {
  display: flex;
}


.App-header {
  margin: 0.5rem 0rem 2rem 0rem;
  font-weight: bolder;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  height: 60px;
}

.App-header-right {
  align-items: flex-end;
  text-align: center;
  height: 50px;
  padding-top: 5px;
  flex-basis:auto;
  display: flex;
}

.App-header-wallet{
  display: inline-block;
}

.App-header-wallet-button:hover{
  border-bottom: 2px solid #A41473;
}

.App-header-wallet-button{
  text-align: center;
  border: 0;
  background: none;
  color: #A41473;
  font-weight: bold;
  font-size: 24px;
  cursor: pointer;
}

.App-header-link {
 color: #248C2D;
 margin:  0.5rem;
 font-size: 24px;
}


.App-header-wallet-display{
  text-align: center;
  border: 0;
  background: none;
  color: #A41473;
  font-size: 20px;
  letter-spacing: -1px;
}


.App-header-wallet-icon{
  font-size: 15px;
  padding: 0 3px 0;
}

.App-header-wallet-disconnect-button{
  text-align: center;
  border: 2px solid #A41473;
  border-radius: 10px;
  background: none;
  color: #A41473;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  margin: 0 0 0 0.5rem;
  width: 160px;
}

.App-header-wallet-disconnect-button:hover{
  background: #a4147314;
}

.App-secondary-header-button-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 200px;
}

.App-secondary-header-button{
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 2px solid white;
  border-radius: 10px;
  font-size: 22px;
  font-weight: bold;
  color: white;
  height: 55px;
  width: 160px;
  cursor: pointer;
  background-color: #248C2D;
}

.App-secondary-header-button:hover{
  width: 98%;
}
.App-secondary-header {
  display:flex;
  width: 100%;
  height: 100%;
  margin: 10px 0 40px;
  border-left: 3px solid #248C2D;

}

.App-secondary-header-title{
  display: inline-block;
  flex-basis: fit-content;
  color: black;
  font-weight: normal;
  padding-left: 15px;
  margin: 0;
}

.App-secondary-header-sub-text{
  font-size: 12px;
}

.App-secondary-header-title-span{
  background-color: #A41473;
  color: white;
  font-weight: bold;
}

@media screen and (max-width: 992px) {
  .App-header {
    font-weight: bolder;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    height: 120px;
    justify-content: space-between;
  }

  .App-secondary-header {
    display:flex;
    width: 100%;
    height: 100%;
    margin: 10px 0 40px;
    border-left: 3px solid #248C2D;
    flex-direction: column;
  }

  .App-secondary-header-button-wrapper {
    flex-basis: 80px;
  }

  .App-logo {
    text-align: center;
  }

  .box-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 30px 0;
    flex-direction: column;
  }

  .day-border {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin: 30px 0;
      flex-direction: column;
    }

    .day-box-img{
      width: initial !important;
    }

    .App-footer{
      flex-direction: column;
    }
}
