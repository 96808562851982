
.wallet-buton{
  font-size: 18px;
  font-weight: 500;
  width: 240px;
  background: white;
  margin: 0.5rem;
  display: flex;
  border: #248C2D 2px solid;
  border-radius: 10px;
  justify-content: space-evenly;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  color: #284A43;
}

.wallet-buton:hover{
  background-color: #284a4321;
  font-weight: bold;
  cursor: pointer;
}

.wallet-buton-img {
  height: 40px;
}
