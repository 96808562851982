
.days-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: column;
  align-content: center
}

.day-border {
    display: flex;
    flex-wrap: nowrap;
    margin: 30px 0;
    flex-direction: row;
  }

.day-title{
  margin: 0;
  color: #A41473;
}


.day-box-img{
  width: 200px !important;
}

.day-level{
  margin: 0;
}

.day-sublevel{
  margin: 0;
  color: grey;
}

.day-content{
}
